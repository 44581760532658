<template>
  <div class="content block-el">
    <template v-if="fetched">
      <PageHeader :title="$t('sms.short-code.edit')" />
      <div class="page-content container-fluid">
        <FormComponent ref="store-shortcode" class="register" @submit="update">
          <div class="row">
            <div class="col-12">
              <CardComponent variant="tertiary">
                <template #header>
                  {{ $tc('app.general') }}
                </template>
                <div class="row">
                  <div class="col-3">
                    <InputComponent v-model="form.name" :label="$tc('app.name', 1)" required type="text"
                      :placeholder="$tc('app.name', 1)" />
                  </div>
                  <div class="col-3">
                    <InputComponent v-model="form.number" label="Shortcode" required type="text"
                      placeholder="Shortcode" />
                  </div>
                  <div class="col-3">
                    <InputComponent v-model="form.deliverability" :label="$tc('app.deliverability', 1)" required
                      type="tel" :rules="['integer', 'min-numeric:0', 'max-numeric:100']"
                      :placeholder="$tc('app.deliverability', 1)">
                      <template #append>
                        <span class="input-group-text">
                          <span class="material-symbols-outlined">
                            percent
                          </span>
                        </span>
                      </template>
                    </InputComponent>
                  </div>
                  <div class="col-3">
                    <InputComponent v-model="form.priority" :label="$tc('app.priority', 1)" required type="text"
                      :rules="['integer', 'min-numeric:0', 'max-numeric:3']" :placeholder="$tc('app.priority', 1)" />
                  </div>
                </div>
              </CardComponent>
            </div>
            <div class="col-12">
              <CardComponent variant="tertiary">
                <template #header>
                  {{ $tc('app.rule', 2) }}
                </template>
                <div class="row" v-for="(rule, n) in form.rules" :key="n">
                  <div class="col-10">
                    <CardComponent>
                      <h4 class="card-title">{{ rule.name }}</h4>
                      <div v-for="(provider, i) in rule.providers" :key="i" class="row">
                        <div class="col-12 col-sm-2">
                          <InputComponent type="tel" v-model="provider.deliverability"
                            :label="$tc('app.deliverability', 1)"
                            :rules="['integer', 'min-numeric:0', 'max-numeric:100']" required>
                            <template #append>
                              <span class="input-group-text">
                                <span class="material-symbols-outlined">
                                  percent
                                </span>
                              </span>
                            </template>
                          </InputComponent>
                        </div>
                        <div class="col-12 col-sm-3">
                          <InputComponent type="tel" v-model="provider.short_code" label="Shortcode"
                            placeholder="Shortcode" required />
                        </div>
                        <div class="col-12 col-sm-2">
                          <InputComponent type="tel" v-model="provider.share" label="Distribuição"
                            placeholder="Distribuição" :rules="['integer', 'min-numeric:0', 'max-numeric:100']"
                            required>
                            <template #append>
                              <span class="input-group-text">
                                <span class="material-symbols-outlined">
                                  percent
                                </span>
                              </span>
                            </template>
                          </InputComponent>
                        </div>
                        <div class="col-12 col-sm-3">
                          <SelectComponent v-model="provider.provider_id" :options="providers"
                            :label="$tc('app.provider', 1)" textField='name' valueField="id" required />
                        </div>
                        <div class="col-12 col-sm-1 pt-2 mt-4 d-flex">
                          <ButtonComponent variant="primary" @click="addProvider(rule)">
                            <span class="material-symbols-outlined">
                              add
                            </span>
                          </ButtonComponent>
                          <ButtonComponent v-if="rule.providers.length > 1" variant="primary" class="ml-2"
                            @click="removeProvider(rule, i)">
                            <span class="material-symbols-outlined">
                              delete
                            </span>
                          </ButtonComponent>
                        </div>
                      </div>
                    </CardComponent>
                  </div>
                  <div class="col-2">
                    <CardComponent>
                      <h1 class="card-title">{{ calcRuleCost(rule) | currency4D }}</h1>
                    </CardComponent>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <ButtonComponent variant="secondary" class="float-right ml-2"
                      @click="$refs['store-shortcode'].submit()">
                      <span class="material-symbols-outlined">
                        save
                      </span>
                    </ButtonComponent>
                  </div>
                </div>
              </CardComponent>
            </div>
          </div>
        </FormComponent>
      </div>
    </template>
    <div v-else class="static qt-block-ui" style="padding: 120px" />
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import ProviderService from '@/services/admin/provider.service';
import SmsService from '@/services/admin/sms.service';
import { mask } from 'vue-the-mask';
import SelectInput from '@/components/SelectInput.vue';
import CardComponent from '@/components/CardComponent.vue';
import FormComponent from '@/components/form/FormComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
  name: 'CreateProduct',
  components: {
    PageHeader,
    CardComponent,
    FormComponent,
    InputComponent,
    SelectComponent,
    ButtonComponent,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      fetched: false,
      types: [
        {
          label: 'SMS',
          value: 'sms',
        },
        {
          label: 'EMAIL',
          value: 'email',
        },
        {
          label: 'WHATSAPP',
          value: 'whatsapp',
        },
        {
          label: 'RCS',
          value: 'rcs',
        },
        {
          label: 'Atendimento',
          value: 'live',
        },
      ],
      providers: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      customer: {},
      form: {
        name: '',
        description: '',
        priority: 0,
        cutting: 0,
        providers: [],
      },
      updating: false,
      pages: 1,
    };
  },
  created() {
    this.form.type = this.types[0].value;
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      SmsService.getShortCode(this.$route.params.id).then(
        (shortCode) => {
          this.form = shortCode;
          this.fetchProviders();
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeType() {
      this.providers = [];
      this.form.providers = [];
      this.fetchProviders();
    },
    fetchProviders() {
      const filter = {};
      filter[this.form.type] = true;
      ProviderService.getProviders({
        sms: true,
      }).then(
        (providers) => {
          this.providers = providers;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    addProvider(rule) {
      rule.providers.push({ ...this.defaultRules });
    },
    removeProvider(rule, i) {
      rule.providers.splice(i, 1);
    },
    calcRuleCost(rule) {
      let cost = 0;
      let share = 0;
      rule.providers.forEach((provider) => {
        const providerData = this.providers.filter((pr) => pr.id === provider.provider_id);
        share += provider.share;
        if (providerData.length) {
          console.log(12312412, ((providerData[0].values.sms[rule.carrier] * provider.deliverability) / 100) * (provider.share / 100));
          cost += ((providerData[0].values.sms[rule.carrier] * provider.deliverability) / 100) * (provider.share / 100);
        }
      });
      return cost;
    },
    update() {
      this.updating = true;
      SmsService.updateShortCode(this.form)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm.customer.prod-updated'),
              type: 'success',
            });
            this.$router.push('./');
          },
          (response) => {
            response.errors.forEach((error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.detail,
                type: 'danger',
              });
            });
          },
        )
        .finally(() => {
          this.updating = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}

.preloader {
  margin: unset;
  display: block;
}
</style>
